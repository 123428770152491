import * as React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import './index.css';
import { useEffect, useState } from 'react';

import L from 'leaflet';
// @ts-ignore
import markerIcon from '../../images/pin.svg';
// @ts-ignore

import markerIcon2 from '../../images/marker.svg';
import Locate from 'leaflet.locatecontrol';

interface JobMapProps {
	data: any;
}

const isFrench = false;
const isLocal = true;
const distance = (
	lat1: number,
	lon1: number,
	lat2: number,
	lon2: number,
	unit: string
) => {
	const R = 6371e3; // metres
	const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
	const φ2 = (lat2 * Math.PI) / 180;
	const Δφ = ((lat2 - lat1) * Math.PI) / 180;
	const Δλ = ((lon2 - lon1) * Math.PI) / 180;
	const a =
		Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
		Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c; // in metres
	return d / 1000;
};
const GetStoreData = (data: any) => {
	let _tempJobs: object[] = [];
	let allJobs = data?.allSitePage?.edges;
	for (let job in allJobs) {
		if (allJobs[job]?.node?.pageContext) {
			if (allJobs[job]?.node?.pageContext?.title) {
				let _job = allJobs[job]?.node?.pageContext;

				// Check if the list already has that store.
				let _store: any = _tempJobs.find(
					(e: any) => e.storeNumber === _job.storeNumber
				);
				if (_store) {
					// If so, add the job to the job list.
					_store?.jobs.push(_job);
				} else {
					// Add a new store to the list
					let _store = {
						storeNumber: _job.storeNumber,
						address: _job.address,
						city: _job.city,
						state: _job.state,
						postalCode: _job.postalCode,
						fullAddress: _job.fullAddress,
						lngLat: _job.lngLat,
						longitude: _job.longitude,
						latitude: _job.latitude,
						jobs: [_job]
					};

					_tempJobs.push(_store);
				}
			}
		}
	}

	console.log('Store Job List: ', _tempJobs);
	return _tempJobs;
};

// const customIcon = new L.Icon({
// 	iconUrl: markerIcon,
// 	iconSize: new L.Point(80, 95)
// });

const createClusterCustomIcon = function (cluster: any) {
	return L.divIcon({
		html: `<span><img class='' style='width: 80px; height: 95px' src='${markerIcon}'><div class='font-bold text-lg' style='position: absolute; width:30px; text-align: center; transform: translate(24px, -47px);'>${cluster.getChildCount()}</div></span>`,
		className: 'custom-marker-cluster',
		iconSize: L.point(80, 95, false)
	});
};

const Localization = (props: {
	storeData: object[];
	coords: number[] | undefined;
}) => {
	const map = useMap();
	// Add the locate me button on the map
	useEffect(() => {
		const locateOptions = {
			position: 'topleft',
			initialZoomLevel: 10,
			showPopup: false
		};
		// @ts-ignore
		const locateControl = new Locate(locateOptions);
		locateControl.addTo(map);
	}, [map]);

	useEffect(() => {
		if (props.storeData) {
			let _stores: any = props.storeData;
			for (let store in _stores) {
				if (_stores.hasOwnProperty(store) && props.coords) {
					_stores[store].distance = distance(
						parseFloat(_stores[store].longitude),
						parseFloat(_stores[store].latitude),
						props.coords[0],
						props.coords[1],
						'K'
					);
				}
			}
			_stores.sort(function (a: any, b: any) {
				let distA = a.distance;
				let distB = b.distance;
				if (distA < distB) {
					return -1;
				} else if (distA > distB) {
					return 1;
				}
				return 0;
			});
			if (props.coords && _stores[0]?.longitude && _stores[0]?.latitude) {
				map.setView(
					[_stores[0].longitude, _stores[0].latitude],
					10,
					{}
				);
			}
		}
	}, [props.storeData, props.coords]);

	return null;
};

// const AddLocateLogic = ({coords, data, geoJsonKey}) => {

//
// 	useEffect(() => {
//
// 		if (data !== undefined) {
// 			// console.log("COORDS~!", coords, data)
// 			for (let job in data) {
// 				if (data.hasOwnProperty(job)) {
// 					data[job]['distance'] = distance(parseFloat(data[job].geometry.coordinates[1]), parseFloat(data[job].geometry.coordinates[0]), coords[0], coords[1], 'K')
// 				}
// 			}
// 			data.sort(function (a, b) {
// 				let distA = a.distance
// 				let distB = b.distance
// 				if (distA < distB) {
// 					return -1;
// 				} else if (distA > distB) {
// 					return 1;
// 				}
// 				return 0;
// 			});
// 			if (data[0] !== undefined && coords[0] !== null) {
// 				if (data[0].geometry !== undefined) {
// 					if (data[0].geometry.coordinates[0] !== null) {
// 						// console.log("Setting Map View! ", coords, data)
// 						map.setView([data[0].geometry.coordinates[1], data[0].geometry.coordinates[0]], 10, {})
// 					}
// 				}
//
// 			}
//
// 		}
// 	}, [coords, data]);
//
// 	return null
// }

const MarkerItem = (props: any) => {
	const map = useMap();
	return (
		<Marker
			key={props.index}
			position={[props.marker.longitude, props.marker.latitude]}
			title={'test'}
			// icon={customIcon}
			eventHandlers={{
				click: (e) => {
					map.flyTo(
						[
							parseFloat(props.marker.longitude) + 0.08,
							props.marker.latitude
						],
						12,
						{
							duration: 0.5
						}
					);
				}
			}}
		>
			<Popup>
				<div className={'flex flex-col'}>
					<span
						className={
							'font-oswald text-xl font-bold text-dirtcheaptext'
						}
					>
						{props.marker.fullAddress}
					</span>
					<span className={'font-source-sans-pro text-lg'}>
						Store# {props.marker.storeNumber}
					</span>
					<span className={'font-source-sans-pro text-lg font-bold'}>
						{props.marker.jobs.length} Opening
						{props.marker.jobs.length === 1 ? '' : 's'}
					</span>
					<div className={'flex flex-col gap-y-3 py-4'}>
						{props.marker.jobs.map((job: any, index: number) => {
							if (index > 2) return null;
							return (
								<a
									href={job.jobPath}
									className={
										'flex cursor-pointer flex-col rounded border bg-gray-100 p-4 pr-8 shadow hover:bg-slate-100'
									}
								>
									<span
										className={
											'font-source-sans-pro text-lg font-bold text-dc-gray'
										}
									>
										{job.title}
									</span>
									<span
										className={
											'font-source-sans-pro text-lg text-dc-gray'
										}
									>
										- {job.category}
									</span>
									<span
										className={
											'font-source-sans-pro text-lg text-dc-gray'
										}
									>
										- {job.fullTime}
									</span>
								</a>
							);
						})}
					</div>
					<a
						href={'/search/?term=' + props.marker.address}
						className={
							'cursor-pointer font-source-sans-pro text-lg font-bold text-red2'
						}
					>
						<span
							className={
								'font-source-sans-pro text-lg font-bold text-dirtcheaptext underline hover:text-dc-gray'
							}
						>
							See all Jobs at this Location
						</span>
					</a>
				</div>
			</Popup>
		</Marker>
	);
};

const JobMap: React.FC<JobMapProps> = (props: JobMapProps) => {
	const data = props.data;
	const storeData = GetStoreData(data);
	const [displayedMarkers, setDisplayedMarkers] = useState(storeData);
	const [coords, setCoords] = useState<number[]>();

	useEffect(() => {
		function getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function setCoordinates(position) {
						const lat = position.coords.latitude;
						const long = position.coords.longitude;
						console.log('Lat: ', lat, ' Long: ', long);
						setCoords([lat, long]);
					}
				);
			}
		}

		getLocation();
	}, []);

	if (typeof window !== 'undefined' && document) {
		return (
			<section className={'px-5'}>
				<div className={'container mx-auto bg-black'}>
					<MapContainer
						// maxBounds={[
						// 	[32, -113],
						// 	[34, -110]
						// ]}
						className={'map-container-dc'}
						tap={false}
						style={{ height: '700px', zIndex: '0 !important' }}
						center={[37.9581403, -91.772843]}
						zoom={5}
						scrollWheelZoom={true}
						attributionControl={false}
					>
						<TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url={`https://api.maptiler.com/maps/${
								isFrench
									? '7ba68c18-9105-41e5-9e76-18e836d16568'
									: 'cd90233e-a380-4cc9-bc28-8fb0d1a5a6b2'
							}/{z}/{x}/{y}.png?key=${
								isLocal
									? 'yYbMBbxgyqxOmY9EPOTS'
									: 'UqYrYv3GlXvHThNSyAgF'
							}`}
						/>
						<MarkerClusterGroup
							chunkedLoading
							iconCreateFunction={createClusterCustomIcon}
						>
							{displayedMarkers.map((marker, index) => {
								return (
									<MarkerItem marker={marker} index={index} />
								);
							})}
							{/**/}
						</MarkerClusterGroup>
						<Localization storeData={storeData} coords={coords} />
					</MapContainer>
				</div>
			</section>
		);
	} else {
		return <>window error.</>;
	}
};

export default JobMap;
