import * as React from 'react';
// @ts-ignore
import hero from '../../images/hero.png';
import JobsNearMe from '../JobsNearMe/JobsNearMe';
interface HeroProps {
	data: any;
}

const Hero: React.FC<HeroProps> = (props: HeroProps) => {
	return (
		<section className="bg-black">
			<div className="mx-auto my-0 w-full max-w-[1176px] pt-[40px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
				<div className="h-100 w-full px-[16px]">
					<h1 className="w-full pb-[32px] text-center font-oswald text-[48px] tracking-[3px] text-dirtcheaptext max-md:text-[30px] max-md:leading-[36px]">
						START AN EXCITING CAREER AT DIRT CHEAP
					</h1>
					<div className="flex flex-row items-center max-[1062px]:flex-col max-[1062px]:pb-[32px]">
						<img
							className="-mb-[56px] text-white max-[1062px]:mb-[0px]"
							src={hero}
							alt="Dirt Cheap Hero"
							width={618}
							height={412}
						/>
						<JobsNearMe data={props.data} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
