import * as React from 'react';
import { useEffect, useState } from 'react';
interface JobsNearMeProps {
	data: any;
}
const getJobData = (data: any) => {
	let _tempJobs: object[] = [];
	let allJobs = data?.allSitePage?.edges;
	for (let job in allJobs) {
		if (allJobs[job]?.node?.pageContext) {
			if (allJobs[job]?.node?.pageContext?.title) {
				let _job = allJobs[job]?.node?.pageContext;

				// Check if the list already has that store.
				let _store: any = _tempJobs.find(
					(e: any) => e.storeNumber === _job.storeNumber
				);
				if (_store) {
					// If so, add the job to the job list.
					_store?.jobs.push(_job);
				} else {
					// Add a new store to the list
					let _store = {
						storeNumber: _job.storeNumber,
						address: _job.address,
						city: _job.city,
						state: _job.state,
						postalCode: _job.postalCode,
						fullAddress: _job.fullAddress,
						lngLat: _job.lngLat,
						longitude: _job.longitude,
						latitude: _job.latitude,
						jobs: [_job]
					};

					_tempJobs.push(_store);
				}
			}
		}
	}

	console.log('Store Job List: ', _tempJobs);
	return _tempJobs;
};
const JobsNearMe: React.FC<JobsNearMeProps> = (props: JobsNearMeProps) => {
	const [coords, setCoords] = useState<number[]>();
	const [jobs, setJobs] = useState(getJobData(props.data));

	const [job1Openings, setJob1Openings] = useState('...');
	const [job2Openings, setJob2Openings] = useState('...');
	const [job1Location, setJob1Location] = useState('...');
	const [job2Location, setJob2Location] = useState('...');
	const [job1Link, setJob1Link] = useState('#');
	const [job2Link, setJob2Link] = useState('#');

	const [jobsNearYouLink, setJobsNearYouLink] = useState('/search/');
	useEffect(() => {
		function getLocation() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					function setCoordinates(position) {
						const lat = position.coords.latitude;
						const long = position.coords.longitude;
						console.log('Lat: ', lat, ' Long: ', long);
						setCoords([lat, long]);
					}
				);
			}
		}
		getLocation();
	}, []);
	useEffect(() => {
		getJobsNearYou(jobs);
	}, [jobs, coords]);
	const distance = (
		lat1: number,
		lon1: number,
		lat2: number,
		lon2: number,
		unit: string
	) => {
		const R = 6371e3; // metres
		const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
		const φ2 = (lat2 * Math.PI) / 180;
		const Δφ = ((lat2 - lat1) * Math.PI) / 180;
		const Δλ = ((lon2 - lon1) * Math.PI) / 180;
		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c; // in metres
		return d / 1000;
	};

	const getJobsNearYou = (jobData: any) => {
		if (!coords || !jobData || !coords[0] || !coords[1]) return;

		for (let job in jobData) {
			if (jobData.hasOwnProperty(job)) {
				jobData[job]['distance'] = distance(
					parseFloat(jobData[job].latitude),
					parseFloat(jobData[job].longitude),
					coords[0],
					coords[1],
					'K'
				);
			}
		}
		jobData.sort(function (a: any, b: any) {
			let distA = a.distance;
			let distB = b.distance;
			if (distA < distB) {
				return -1;
			} else if (distA > distB) {
				return 1;
			}
			return 0;
		});
		if (!jobData[0] || !jobData[1]) return;
		setJob1Location(jobData[0].fullAddress);
		setJob2Location(jobData[1].fullAddress);
		setJob1Link(`/search/?term=${jobData[0].fullAddress}`);
		setJob2Link(`/search/?term=${jobData[1].fullAddress}`);
		setJob1Openings('1 Opening');
		setJob2Openings('1 Opening');
		setJobsNearYouLink(`/search/?state=${jobData[1].state}`);
	};

	return (
		<div className="max-[1062px]: flex w-full flex-col items-center justify-center pl-20 max-[1240px]:pl-[0px]">
			<h2 className="font-oswald text-[48px] font-bold text-dirtcheaptext max-md:text-[24px] max-md:leading-[28px]">
				JOBS IN YOUR AREA
			</h2>
			<a
				href={job1Link}
				className="mt-[24px] flex h-[56px] w-full max-w-md  cursor-pointer flex-col items-center justify-center rounded-[8px] border border-white text-center font-source-sans-pro text-white hover:text-white max-md:h-full max-md:p-[12px]"
			>
				<p className="mb-0 text-[18px] leading-[22px]">
					{/*<strong>3 Opening</strong>*/}
					<strong>{job1Openings}</strong>
				</p>
				<p className="text-[18px] leading-[22px]">{job1Location}</p>
			</a>
			<a
				href={job2Link}
				className="mt-[24px] flex h-[56px] w-full max-w-md  cursor-pointer flex-col items-center justify-center rounded-[8px] border border-white text-center font-source-sans-pro text-white hover:text-white max-md:h-full max-md:p-[12px]"
			>
				<p className="mb-0 text-[18px] leading-[22px]">
					{/*<strong>4 Openings</strong>*/}
					<strong>{job2Openings}</strong>
				</p>
				<p className="text-[18px] leading-[22px]">{job2Location}</p>
			</a>
			<a href={jobsNearYouLink}>
				<p className="pt-[16px] text-center font-source-sans-pro text-[20px] font-bold leading-[28px] tracking-wide text-dirtcheaptext underline">
					View More Jobs in Your Area
				</p>
			</a>
		</div>
	);
};

export default JobsNearMe;
