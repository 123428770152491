import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import './index.scss';
import Layout from '../layouts/Layout';
import Hero from '../components/Hero/Hero';
// @ts-ignore
import footer1 from '../images/stocking-shelves.png';
// @ts-ignore
import footer2 from '../images/stocking-shelves-2.png';
// @ts-ignore
import footer3 from '../images/waving-at-customers.png';
// @ts-ignore
import otrLogo from '../images/on-the-run-logo-white.svg';
import JobMap from '../components/JobMap/JobMap';
import { graphql, useStaticQuery } from 'gatsby';
import ExploreJobsSection from '../components/ExploreJobsSection/ExploreJobsSection';

const IndexPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			title={'Dirt Cheap | Employment Opportunities'}
			desc={'Dirt Cheap | Employment Opportunities'}
		>
			<Hero data={data} />
			<section className="bg-white pb-[100px]">
				{/* MAP GOES HERE */}
			</section>
			<ExploreJobsSection data={data} />
			<JobMap data={data} />
			<section>
				<div className="mx-auto my-0 w-full max-w-[1100px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
					<div className="font-source-sans-pr w-full py-14 px-4 pb-40">
						<p className="text-3xl text-[#2C2C2C] max-lg:text-xl">
							Our two greatest strengths are no secret – our
							wonderful customers and our great team members. If
							you were thinking about working at Dirt Cheap, here
							are some reasons that may help you decide:
						</p>
						<ul className="py-5 text-lg text-[#2C2C2C]">
							<li className="my-2">
								<strong className="font-bold">
									We have fun.
								</strong>{' '}
								Enough said!
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Full Time Work
								</strong>{' '}
								– 80% of our employees are full time. Yes, we
								hire full time!
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Low Turnover
								</strong>{' '}
								- On average our employees stay with us more
								than 3 years
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Family Owned
								</strong>{' '}
								– Not a big corporation here
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Opportunities
								</strong>{' '}
								– We promote from within
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Open Door Policy
								</strong>
							</li>
							<li className="my-2">
								<strong className="font-bold">
									Safe Work Environment
								</strong>{' '}
								– All employees must pass pre-employment
								criminal background and drug screens, and our
								stores all have state of the art surveillance
								video systems.
							</li>
						</ul>
						<h2 className="mt-6 font-oswald text-5xl text-dirtcheaptext max-md:text-3xl">
							EMPLOYER OF CHOICE
						</h2>
						<p className="pt-9 text-lg text-[#2C2C2C]">
							Becoming a team member of the Dirt Cheap family has
							many benefits. Our overall compensation package
							makes us “the employer of choice” within the
							convenience store industry in the St. Louis market.
							We also have the best benefit and wage package
							locally for St. Louis careers. Benefits include:
						</p>
						<ul className="py-5 text-lg text-[#2C2C2C]">
							<li className="my-2">Competitive Wages</li>
							<li className="my-2">
								Receive an opportunity for a raise after just 60
								days!
							</li>
							<li className="my-2">
								Affordable health, dental and vision insurance
							</li>
							<li className="my-2">
								Paid vacation for full AND part-time employees
							</li>
							<li className="my-2">
								Company paid Employee Assistance Program
							</li>
							<li className="my-2">401k with a company match</li>
							<li className="my-2">Service Awards</li>
							<li className="my-2">
								Company Sponsored Events (Our Christmas parties
								are fabulous!)
							</li>
							<li className="my-2">Plus many more…</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="bg-[#da3831]">
				<div className="mx-auto my-0 w-full max-w-[1100px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
					<div className="w-full px-4 pb-12 font-source-sans-pro">
						<div className="flex w-full flex-wrap items-center justify-center">
							<img
								className="-mt-24 max-[1062px]:w-80"
								src={footer1}
								alt="Footer Image"
								width={340}
								height={340}
							/>
							<img
								className="-mt-24 max-[1062px]:w-80 max-[680px]:mt-0"
								src={footer2}
								alt="Footer Image"
								width={340}
								height={340}
							/>
							<img
								className="-mt-24 max-[1062px]:w-80 max-[991px]:mt-0"
								src={footer3}
								alt="Footer Image"
								width={340}
								height={340}
							/>
						</div>
						<div className="w-full pt-8 text-center">
							<a
								className="pt-7 font-source-sans-pro text-xl font-normal text-white underline hover:text-white hover:underline"
								href={'https://careers.ontherunstl.com'}
								target={'_blank'}
							>
								Explore Other Career Opportunities at
							</a>
							<img
								className="mx-auto mt-6"
								src={otrLogo}
								alt="On The Run Logo"
								width={186}
								height={63}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
